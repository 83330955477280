
















































































































































import { Component, Vue } from 'vue-property-decorator';
import { IProduct, ISignupProfile, ICouponCode } from '@/scripts/models';
import { ApiProfile } from '@/scripts/apiProfile.ts';
import { ApiOtp } from '@/scripts/apiOTP.ts';
import * as lib from '@/scripts/apiHelper.ts';
import * as rules from '@/scripts/rules.ts';
import { ProductPrice } from '@/scripts/productPrice';
import Product from '@/components/Products/Product.vue';
import Terms from '@/components/Terms.vue';
import StepperButtons from '@/components/StepperButtons.vue';
import OrderSummary from '@/components/OrderSummary.vue';

@Component({ components: { StepperButtons, Terms, Product, OrderSummary } })
export default class SignupWizard extends Vue {
  // Snackbar
  private snackbarShown = false;
  private snackbarColor = '';
  private snackbarText = '';

  // Stepper
  private showWizardDialog = false;
  private showTermsDialog = false;
  private stepNumber = 1;

  // Products
  private products = [] as IProduct[];
  private selectedProduct = {} as IProduct;
  private productErrorMessage = '';

  // Profile
  private profile = {} as ISignupProfile;
  private passwordCompare = '';
  private formValid = false;
  private showPwd = false;
  private userExists = true;
  private userError = '';

  // Payment
  private price = new ProductPrice();
  private coupon = {} as ICouponCode;

  // OTP
  private enteredOtpCode = '';
  private otpSendTimer = 0;
  private otpVerifyTimer = 0;
  private otpVerificationOk = false;
  private otpError = '';

  private verifyButtonEnabled = true;
  private sendButtonEnabled = true;
  private termsAccepted = false;

  private error = '';

  // Show the sign up wizard
  public async showSignupWizard(chosenProduct: IProduct): Promise<void> {
    if (chosenProduct) {
      this.showWizardDialog = true;
      this.stepNumber = 1;
      this.setPackage(chosenProduct);
      this.$nextTick(() => {
        (this.$refs.stepperButtons as StepperButtons).resetStep();
      });

      this.formValid = false;
      this.otpVerificationOk = false;
      this.userExists = true;
    }
  }

  private hideSignupWizard(): void {
    this.showWizardDialog = false;
    this.clearProfile();
  }

  private clearProfile(): void {
    if (this.profile) {
      this.profile = {} as ISignupProfile;
    }
  }

  // Controls prev and next buttons
  private validateStep(step: number): boolean {
    switch (step) {
      case 1:
        return this.selectedProduct.ProductId > 0;
      case 2:
        return this.formValid && this.otpVerificationOk && !this.userExists;
      case 3:
        return this.termsAccepted;
      default:
        return false;
        break;
    }
  }

  private stepChanged(nextStep: number): void {
    this.stepNumber = nextStep;
  }

  // Show snackbar
  private showSnackbar(text: string, color: string): void {
    this.snackbarText = text;
    this.snackbarColor = color;
    this.snackbarShown = true;
  }

  // ***************
  // Step 1
  // ***************

  private setPackage(product: IProduct): void {
    this.selectedProduct = product;
    this.profile.ProductId = this.selectedProduct.ProductId;
    this.price.price = this.selectedProduct.ProductMonthlyPrice;
    const elmnt = document.getElementById('stepperButtons');
    if (elmnt) {
      elmnt.scrollIntoView();
    }
  }

  // ***************
  // Step 2
  // ***************

  // Button VERIFICER clicker
  private async verifyUserAndOtp(): Promise<void> {
    if (!this.otpVerificationOk) {
      await this.verifyOTPCode();
    }
    if (this.userExists && this.profile.UserName && this.profile.UserName.length > 0) {
      await this.verifyUserName();
    }
  }

  private async sendOTPCode(): Promise<void> {
    if (this.profile.PhoneNumber && this.profile.PhoneNumber.length == 8) {
      //send code and disable button for 8 sec.
      this.enteredOtpCode = '';
      this.disableSendButton(8000);
      this.disableVerifyButton(8000);
      await ApiOtp.postSend({
        PhoneNumber: this.profile.PhoneNumber
      });
      this.$nextTick((this.$refs.otpField as HTMLInputElement).focus);
    }
  }

  private async verifyOTPCode(): Promise<void> {
    if (this.enteredOtpCode.length > 0) {
      try {
        this.otpError = '';
        //verify code and disable buttons
        this.disableSendButton(8000);
        this.disableVerifyButton(8000);
        const result = await ApiOtp.verifyOtpCode(this.profile.PhoneNumber, this.enteredOtpCode);
        this.otpVerificationOk = result.Result;
        if (!this.otpVerificationOk) {
          this.otpError = 'Fejl i kode.';
        }
      } catch (error) {
        this.otpError = lib.getErrorMessage(error);
      }
    } else {
      this.otpError = 'Indtast venligst 6-cifret kode';
    }
  }

  // Test if user exists
  private async verifyUserName(): Promise<void> {
    this.userError = '';
    const result = await ApiProfile.exists(this.profile.UserName);
    this.userExists = result.Result;

    if (this.userExists) {
      this.userError = 'Bruger eksisterer allerede.';
    }
  }

  // Disable button for x sec. 0=inf
  private disableVerifyButton(interval: number): void {
    this.verifyButtonEnabled = false;
    if (interval > 0) {
      this.otpVerifyTimer = setInterval(() => this.enableVerifyButton(), interval);
    }
  }

  // Enable button and stop timer
  private enableVerifyButton(): void {
    this.verifyButtonEnabled = true;
    clearInterval(this.otpVerifyTimer);
  }

  // Disable button for x sec. 0=inf
  private disableSendButton(interval: number): void {
    this.sendButtonEnabled = false;
    if (interval > 0) {
      this.otpSendTimer = setInterval(() => this.enableSendButton(), interval);
    }
  }

  // Enable button and stop timer
  private enableSendButton(): void {
    this.sendButtonEnabled = true;
    clearInterval(this.otpSendTimer);
  }

  get emailRules(): ((v: string) => boolean | string)[] {
    return rules.emailRules();
  }

  get passwordRules(): ((v: string) => boolean | string)[] {
    return rules.passwordRules();
  }

  get passwordCompareRules(): ((v: string) => boolean | string)[] {
    return [
      (v: string): boolean | string => !!v || 'Kodeord skal angives',
      (): boolean | string => this.passwordCompare === this.profile.Password || 'Kodeord er ikke ens'
    ];
  }

  get phoneRules(): ((v: string) => boolean | string)[] {
    return rules.phoneRules(true);
  }

  // ***************
  // Step 3
  // ***************

  // Apply discount
  private applyCoupon(discount: number): void {
    this.price.discountPercent = discount;
  }

  private async createProfile(): Promise<void> {
    this.error = '';
    try {
      const result = await ApiProfile.create({
        UserName: this.profile.UserName,
        Password: btoa(this.profile.Password),
        ProductId: this.profile.ProductId,
        MonthlyAmount: this.price.total,
        PhoneNumber: this.profile.PhoneNumber,
        OtpCode: this.enteredOtpCode
      });

      if (result) {
        window.location.href = result.Link;
      } else {
        this.error = 'Betalingsgateway ikke tilgængelig. Prøv venligst igen.';
      }
    } catch (error) {
      this.error = lib.getErrorMessage(error);
    }
  }
}
