export class ProductPrice {
  private _price = 0; //  Product price
  private _discountPercent = 0; //  Discount in %
  private _discount = 0; //  Discount
  private _subTotal = 0; //  Sub total price
  private _vat = 0; // VAT price
  private _total = 0; // Total price

  constructor() {
    this._price = 0;
    this._discountPercent = 0;
    this._discount = 0;
    this._subTotal = 0;
    this._vat = 0;
    this._total = 0;
  }

  get price(): number {
    return this._price;
  }
  set price(price: number) {
    this._price = price;
    this.reCalculate();
  }

  get discount(): number {
    return this._discount;
  }

  get discountPercent(): number {
    return this._discountPercent;
  }
  set discountPercent(percent: number) {
    this._discountPercent = percent;
    this.reCalculate();
  }

  get subTotal(): number {
    return this._subTotal;
  }
  get VAT(): number {
    return this._vat;
  }
  get total(): number {
    return this._total;
  }

  // Calc prices
  private reCalculate(): void {
    this._discount = this._price * (this._discountPercent / 100);
    this._subTotal = this._price - this._discount;
    this._vat = this._subTotal / 5;
    this._total = this._price - this._discount;
  }
}
