//
// A collection of helper functions and classes
//

import { IOTPSendResult, IOTPData } from '@/scripts/models';
import { getApi } from '@/scripts/apiHelper.ts';

// API calls
export class ApiOtp {
  public static apiBase = 'otp';
  // OTP controller calls

  // Send OTP code
  public static async postSend(payload: IOTPData): Promise<IOTPSendResult> {
    const response = await (await getApi(this.apiBase)).post('', payload);
    return response.data as IOTPSendResult;
  }

  // Verify OTP code
  public static async verifyOtpCode(phoneNumber: string, otpCode: string): Promise<IOTPSendResult> {
    const url = `${encodeURIComponent(phoneNumber)}/${encodeURIComponent(otpCode)}`;
    const response = await (await getApi(this.apiBase)).get(url);
    return response.data as IOTPSendResult;
  }
}
