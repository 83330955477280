
















import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
export default class StepperButtons extends Vue {
  @Prop({ required: true, default: 1 })
  public maxSteps!: number;
  @Prop({ required: true, default: false })
  public backButtonEnabled!: boolean;
  @Prop({ required: true, default: false })
  public forwardButtonEnabled!: boolean;

  private stepNumber = 1;

  public resetStep(): void {
    this.stepNumber = 1;
  }

  private decStep(): void {
    if (this.stepNumber > 1) {
      this.stepNumber--;
      this.$emit('step-changed', this.stepNumber);
    }
  }

  private incStep(): void {
    if (this.stepNumber < this.maxSteps) {
      this.stepNumber++;
      this.$emit('step-changed', this.stepNumber);
    } else if (this.stepNumber === this.maxSteps) {
      this.$emit('final-step');
    }
  }
}
