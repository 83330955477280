























import { Component, Vue, Prop } from 'vue-property-decorator';
import { IProduct } from '@/scripts/models';

@Component({ components: {} })
export default class Product extends Vue {
  @Prop({ required: true })
  private product!: IProduct;
  @Prop({ required: false, default: false })
  private isSelected!: boolean;

  // Product clicked -> emit product
  private productClicked(product: IProduct): void {
    this.$emit('product-clicked', product);
  }
}
