

































































import { Component, Vue, Prop } from 'vue-property-decorator';
import { ApiCommon } from '@/scripts/apiCommon.ts';
import { IProduct } from '@/scripts/models';
import { ProductPrice } from '@/scripts/productPrice';

@Component
export default class OrderSummary extends Vue {
  @Prop({ required: true })
  private product!: IProduct;
  @Prop({ required: true })
  private price!: ProductPrice;

  // Snackbar
  private snackbarShown = false;
  private snackbarColor = '';
  private snackbarText = '';

  // Coupon
  private couponCode = '';

  // Fetch coupn code discount from api
  private async useCouponCode(): Promise<void> {
    try {
      const coupon = await ApiCommon.getCouponCode(this.couponCode, this.product.ProductId);
      this.$emit('coupon-used', coupon.Discount);
      this.couponCode = '';
      if (coupon.Discount && coupon.Discount > 0) {
        this.showSnackbar('Kode indløst', 'success');
      } else {
        this.showSnackbar('Kode ugyldig', 'error');
      }
    } catch (error) {
      this.showSnackbar('Kode ugyldig', 'error');
    }
  }

  // Show snackbar
  private showSnackbar(text: string, color: string): void {
    this.snackbarText = text;
    this.snackbarColor = color;
    this.snackbarShown = true;
  }
}
